
import { defineComponent, PropType, Ref, ref, watch } from 'vue';
import HeadingComponent from '@/components/HeadingComponent.vue';

export default defineComponent({
  components: {
    HeadingComponent,
  },
  emits: ['nutritional-plan-deleted', 'nutritional-plan-enabled-changed'],
  props: {
    loadingNutritionalPlan: {
      type: Boolean as PropType<boolean>,
      required: true,
    },
    title: {
      type: String as PropType<string>,
      required: true,
    },
    tabsMenu: {
      type: Array as PropType<Array<any>>,
      required: true,
    },
    breadcrumb: {
      type: Array as PropType<Array<any>>,
      required: true,
    },
    userCanEdit: {
      type: Boolean as PropType<boolean>,
      required: true,
    },
    nutritionalPlanEnabled: {
      type: Boolean as PropType<boolean>,
      required: true,
    },
  },
  setup(props, { emit }) {
    const nutritionalPlanEnabledRef: Ref<boolean> = ref(props.nutritionalPlanEnabled);

    const onDeleteNutritionalPlan = () => {
      emit('nutritional-plan-deleted');
    };

    watch(nutritionalPlanEnabledRef, (newValue) => {
      emit('nutritional-plan-enabled-changed', newValue);
    });

    return { nutritionalPlanEnabledRef, onDeleteNutritionalPlan };
  },
});
