
import { nutritionalPlanService } from '@/services/NutritionalPlanService';
import { useToast } from 'primevue/usetoast';
import { defineComponent, ref } from 'vue';
import { useI18n } from 'vue-i18n';

export default defineComponent({
  emits: ['nutritional-plan-deleted'],
  props: {
    patientId: {
      type: String,
      required: true,
    },
    nutritionalPlanId: {
      type: String,
      required: true,
    },
  },

  setup(props, { emit }) {
    const { t } = useI18n();
    const toast = useToast();

    const showDialog = ref(false);
    const waitSubmit = ref(false);

    const openDialog = () => {
      showDialog.value = true;
    };

    const onSubmit = async () => {
      await deleteNutritionalPlan();
    };

    async function deleteNutritionalPlan() {
      waitSubmit.value = true;
      await nutritionalPlanService
        .delete(props.patientId, props.nutritionalPlanId)
        .then(() => {
          toast.add({
            severity: 'success',
            summary: `${t('messages.notifications.successDeleteNutritionalPlan')}`,
            life: 3000,
          });
          emit('nutritional-plan-deleted');
          showDialog.value = false;
        })
        .catch(() => {
          toast.add({
            severity: 'error',
            summary: `${t('messages.notifications.errorDeleteNutritionalPlan')}`,
            life: 3000,
          });
        })
        .finally(() => {
          waitSubmit.value = false;
        });
    }

    return {
      showDialog,
      waitSubmit,
      openDialog,
      onSubmit,
    };
  },
});
