import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, vShow as _vShow, withDirectives as _withDirectives, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "nutritional__plan-detail__actions" }
const _hoisted_2 = { class: "nutritional__plan-detail__cancel-p" }
const _hoisted_3 = { class: "nutritional__plan__enabled" }
const _hoisted_4 = { for: "switchEnabled" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_InputSwitch = _resolveComponent("InputSwitch")!
  const _component_HeadingComponent = _resolveComponent("HeadingComponent")!

  return (!_ctx.loadingNutritionalPlan)
    ? (_openBlock(), _createBlock(_component_HeadingComponent, {
        key: 0,
        title: _ctx.title,
        "heading-icon": "icon-nutrition",
        tabs: _ctx.tabsMenu,
        breadcrumb: _ctx.breadcrumb
      }, {
        right: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            _withDirectives(_createElementVNode("div", {
              class: "nutritional__plan-detail__cancel",
              onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onDeleteNutritionalPlan && _ctx.onDeleteNutritionalPlan(...args)))
            }, [
              _cache[2] || (_cache[2] = _createElementVNode("i", {
                class: "icon-trash_full nutritional__plan-detail__cancel-icon",
                "aria-hidden": "true"
              }, null, -1)),
              _createElementVNode("p", _hoisted_2, _toDisplayString(_ctx.$t('common.delete')), 1)
            ], 512), [
              [_vShow, _ctx.userCanEdit]
            ]),
            _createElementVNode("div", _hoisted_3, [
              _createVNode(_component_InputSwitch, {
                modelValue: _ctx.nutritionalPlanEnabledRef,
                "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.nutritionalPlanEnabledRef) = $event)),
                inputId: "switchEnabled"
              }, null, 8, ["modelValue"]),
              _createElementVNode("label", _hoisted_4, _toDisplayString(_ctx.$t('nutrition.nutritionalPlan.enabled-plan')), 1)
            ])
          ])
        ]),
        _: 1
      }, 8, ["title", "tabs", "breadcrumb"]))
    : _createCommentVNode("", true)
}